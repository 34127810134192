// Generated by Framer (9f979fb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["er04AfEuz", "scQaVuYMp", "psjHFu20h"];

const serializationHash = "framer-XRbzz"

const variantClassNames = {er04AfEuz: "framer-v-az3xi", psjHFu20h: "framer-v-1fvtkgb", scQaVuYMp: "framer-v-133vo8a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {L: "er04AfEuz", M: "scQaVuYMp", S: "psjHFu20h"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "er04AfEuz"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "er04AfEuz", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-az3xi", className, classNames)} data-border data-framer-name={"L"} layoutDependency={layoutDependency} layoutId={"er04AfEuz"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1.5px", "--border-color": "var(--token-80553515-7ab0-44ac-b981-96d7680674fd, rgb(255, 255, 255)) /* {\"name\":\"Neutral / Surface Main\"} */", "--border-left-width": "1.5px", "--border-right-width": "1.5px", "--border-style": "solid", "--border-top-width": "1.5px", backgroundColor: "var(--token-0dc4c26f-f3d9-46d4-b4db-4633e1b3b583, rgb(34, 197, 94))", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, ...style}} {...addPropertyOverrides({psjHFu20h: {"data-framer-name": "S"}, scQaVuYMp: {"data-framer-name": "M"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-XRbzz[data-border=\"true\"]::after, .framer-XRbzz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XRbzz.framer-oyojub, .framer-XRbzz .framer-oyojub { display: block; }", ".framer-XRbzz.framer-az3xi { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; min-height: 18px; min-width: 18px; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-XRbzz.framer-az3xi { gap: 0px; } .framer-XRbzz.framer-az3xi > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-XRbzz.framer-az3xi > :first-child { margin-left: 0px; } .framer-XRbzz.framer-az3xi > :last-child { margin-right: 0px; } }", ".framer-XRbzz.framer-v-133vo8a.framer-az3xi { min-height: 14px; min-width: 14px; }", ".framer-XRbzz.framer-v-1fvtkgb.framer-az3xi { min-height: 10px; min-width: 10px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 18
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"],"constraints":["18px",null,"18px",null]},"scQaVuYMp":{"layout":["auto","auto"],"constraints":["14px",null,"14px",null]},"psjHFu20h":{"layout":["auto","auto"],"constraints":["10px",null,"10px",null]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerVlixpZpfK: React.ComponentType<Props> = withCSS(Component, css, "framer-XRbzz") as typeof Component;
export default FramerVlixpZpfK;

FramerVlixpZpfK.displayName = "Base / Avatar Status";

FramerVlixpZpfK.defaultProps = {height: 18, width: 18};

addPropertyControls(FramerVlixpZpfK, {variant: {options: ["er04AfEuz", "scQaVuYMp", "psjHFu20h"], optionTitles: ["L", "M", "S"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerVlixpZpfK, [])